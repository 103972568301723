import { departmentService, positionService } from '@/api';
import { DepartmentResource, PositionResource } from '@/resource/model';
import { Component, PropSync } from 'vue-property-decorator';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTable, OsTableQuery, OsPagination } from '@/components';
import { ResourceStatusEnum } from '@/resource/enum';
import { CascaderOption } from 'element-ui/types/cascader-panel';
import { messageError } from '@/utils';
import { Table } from 'element-ui/types/element-ui';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
@Component({
  name: 'select-position',
  components: { OsTable, OsTableQuery, OsPagination }
})
export default class SelectPosition extends mixins(PagingMixin) {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false }) public syncedVisible!: boolean;
  public pageTotal = 0;
  public tableOption: OsTableOption<PositionResource> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true
  };
  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<PositionResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'posCode',
      label: 'position.code',
      minWidth: '100px'
    },
    { prop: 'posName', label: 'position.name', minWidth: '100px', showOverflowTooltip: true },
    {
      prop: 'parentName',
      label: 'position.parentName',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'depName',
      label: 'department.departmentName',
      showOverflowTooltip: true,
      minWidth: '110px'
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '150px'
    }
  ];
  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      },
      colSpan: 9
    },
    {
      type: 'Cascader',
      field: 'depIdList',
      label: 'department.department',
      className: 'department',
      option: {
        placeholder: 'department.selectDepartment',
        filterable: true,
        clearable: true,
        collapseTags: true,
        showAllLevels: false,
        props: {
          checkStrictly: true,
          emitPath: false,
          multiple: true
        }
      },
      colSpan: 9,
      optionData: []
    }
  ];
  public selectedRows: Array<PositionResource> = [];

  private queryForm: Partial<{
    keywords: string;
    readonly status: ResourceStatusEnum;
    depIdList: Array<number>;
  }> = {
    keywords: '',
    status: ResourceStatusEnum.using,
    depIdList: []
  };

  public dialogOpen(): void {
    this.reloadData();
    this.getDepartments();
  }

  public dialogClosed(): void {
    (this.$refs.tableQuery as OsTableQuery).resetQuery();
    this.clearSelection();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.getPosition();
  }

  public handlePaging(): void {
    this.getPosition();
  }

  public handleSelectionChange(selectedData: Array<PositionResource>): void {
    this.selectedRows = [...selectedData];
  }

  public onSubmit(): void {
    this.$emit('confirm', this.selectedRows);
    this.syncedVisible = false;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    ((this.$refs.positionTable as OsTable).tableRef as Table).clearSelection();
  }

  private getPosition(): void {
    this.tableOption.loading = true;
    positionService
      .getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
        if (this.pageTotal === 0) {
          this.pageTotal = res.total;
        }
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private async getDepartments(): Promise<void> {
    try {
      const departments = await departmentService.getList({});
      const deptQuery = this.queryItemsOption.find(x => x.field === 'depIdList');
      deptQuery!.optionData = this.handleCascaderOption(departments);
    } catch (error) {
      messageError(error);
    }
  }

  private handleCascaderOption(departments: Array<DepartmentResource>): Array<CascaderOption> {
    return departments.map(x => {
      const option: CascaderOption = {
        label: x.depName,
        value: x.id
      };
      if (x.children && x.children.length > 0) {
        option.children = this.handleCascaderOption(x.children);
      }
      return option;
    });
  }
}
