import { productionOrderChangeService } from '@/api';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { ProductionOrderProductResource } from '@/resource/model';
import { messageError } from '@/utils';
import { Table } from 'element-ui';
import { mixins } from 'vue-class-component';

import { Component, Prop, PropSync } from 'vue-property-decorator';

@Component({
  name: 'select-production-order-product',
  components: {}
})
export default class SelectProductionOrderProduct extends mixins(PagingMixin) {
  /**
   * 生产订单ID
   */
  @Prop({
    required: true,
    type: Number
  })
  public orderId!: number;

  /**
   * 是否开启多选，默认开启
   */
  @Prop({
    required: false,
    type: Boolean,
    default: true
  })
  public isMultiple!: boolean;

  /**
   * 弹窗显示控制
   */
  @PropSync('visible', {
    default: false
  })
  public syncedVisible!: boolean;

  /**
   * 检索条件
   */
  public queryForm: {
    orderId: number | null;
  } = {
    orderId: null
  };

  /**
   * 表格配置
   */
  public tableOption: OsTableOption<ProductionOrderProductResource> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    rowKey: (): string => {
      return 'id';
    },
    radioSelection: !this.isMultiple
  };

  /**
   * 表格列配置
   */
  public tableColumnOption: Array<OsTableColumn<ProductionOrderProductResource>> = [
    {
      prop: 'itemCode',
      label: 'orderProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'orderProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'orderProduct.customProductName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'orderProduct.platformProductName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'orderProduct.count',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleWidth',
      label: 'orderProduct.visibleSize',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishWidth',
      label: 'orderProduct.completeSize',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressPicture',
      label: 'orderProduct.prepressPicture',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'orderProduct.prepressDescription',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'orderProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'orderProduct.requiredDeliveryTime',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredArriveTime',
      label: 'orderProduct.requiredArriveTime',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 总记录数
   */
  public pageTotal = 0;

  public selectedRows: Array<ProductionOrderProductResource> = [];
  public selectedRow: ProductionOrderProductResource | null = null;

  public created(): void {
    if (this.isMultiple) {
      this.tableColumnOption.unshift({
        type: 'selection',
        prop: 'id',
        label: '',
        reserveSelection: true
      });
    }
  }

  /**
   * 弹窗开启事件
   */
  public dialogOpen(): void {
    this.queryForm.orderId = this.orderId;
    // 加载项次数据
    this.loadData();
  }

  /**
   * 弹窗关闭后事件
   */
  public dialogClosed(): void {
    this.resetQuery();
    this.clearSelection();
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    if (this.isMultiple) {
      ((this.$refs.productTable as OsTable).tableRef as Table).clearSelection();
    } else {
      (this.$refs.productTable as OsTable).clearRadioSelection();
    }
  }

  /**
   * 数据多选事件
   */
  public handleSelectionChange(selectedValues: Array<ProductionOrderProductResource>): void {
    this.selectedRows = [...selectedValues];
  }

  /**
   * 数据单选事件
   */
  public handleRadioSelectionChange(selectedValue: ProductionOrderProductResource): void {
    this.selectedRow = selectedValue;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get radioConfirmDisabled(): boolean {
    return !this.selectedRow;
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  /**
   * 分页事件
   */
  public handlePaging(): void {
    this.loadData();
  }

  /**
   * 提交事件
   */
  public onSubmit(): void {
    if (this.isMultiple) {
      this.$emit('confirm', this.selectedRows);
    } else {
      this.$emit('confirm', this.selectedRow);
    }
    this.syncedVisible = false;
  }

  /**
   * 加载项目产品数据
   */
  private loadData(): void {
    (this.$refs.productTable as OsTable).openLoading();

    productionOrderChangeService
      .getProductionOrderItemList(this.queryForm as any, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.pageTotal = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.productTable as OsTable).closeLoading();
      });
  }

  private resetQuery(): void {
    this.queryForm = {
      orderId: null
    };
  }
}
