import { CustomerProductResource } from '@/resource/model/customer-management/customer-product.d';
import { ProjectProductList, SelectProjectProductQuery } from '@/resource/model';
import OsTable, { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { mixins } from 'vue-class-component';

import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { classifyService, productionProductSearchService } from '@/api';
import { dateFormat, messageError } from '@/utils';
import { Table } from 'element-ui';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
@Component({
  name: 'select-project-product',
  components: {}
})
export default class SelectProjectProduct extends mixins(PagingMixin) {
  /**
   * 项目ID
   */
  @Prop({
    required: true,
    type: Number
  })
  public projectId!: number;

  @Prop({
    required: true,
    type: Number
  })
  public orderId!: number;

  /**
   * 供应商ID
   */
  @Prop({
    required: true,
    type: Number
  })
  public supplierId!: number;

  /**
   * 项次ID集合
   */
  @Prop({
    required: false,
    type: Array,
    default: () => {
      return [];
    }
  })
  public projectItemIdList!: Array<number>;

  /**
   * 是否开启多选，默认开启
   */
  @Prop({
    required: false,
    type: Boolean,
    default: true
  })
  public isMultiple!: boolean;

  /**
   * 弹窗显示控制
   */
  @PropSync('visible', {
    default: false
  })
  public syncedVisible!: boolean;

  public isInit = false;

  /**
   * 检索条件
   */
  public queryForm: Partial<SelectProjectProductQuery> = {
    projectId: this.projectId,
    id: this.orderId,
    supplierId: this.supplierId,
    projectItemIdList: this.projectItemIdList
  };

  /**
   * 项目可选客户产品数据
   */
  public projectCustomProductList: Array<CustomerProductResource> = [];

  /**
   * 表格配置
   */
  public tableOption: OsTableOption<ProjectProductList> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    radioSelection: !this.isMultiple
  };

  /**
   * 表格列配置
   */
  public tableColumnOption: Array<OsTableColumn<ProjectProductList>> = [
    {
      prop: 'itemCode',
      label: 'orderProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'orderProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'orderProduct.customProductName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'orderProduct.platformProductName',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'orderProduct.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'orderProduct.count',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleWidth',
      label: 'orderProduct.visibleSize',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishWidth',
      label: 'orderProduct.completeSize',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressPicture',
      label: 'orderProduct.prepressPicture',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'orderProduct.prepressDescription',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'orderProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'orderProduct.requiredDeliveryTime',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredArriveTime',
      label: 'orderProduct.requiredArriveTime',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public queryItemsOption: Array<OsQueryItemOption<SelectProjectProductQuery>> = [
    {
      type: 'Input',
      field: 'itemCode',
      label: 'projectProduct.itemCode',
      colSpan: 8,
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'pointName',
      label: 'projectProduct.pointName',
      colSpan: 8,
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'projectProduct.platformProduct',
      colSpan: 8,
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'customerProductName',
      label: 'projectProduct.customerProduct',
      colSpan: 8,
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Cascader',
      field: 'categoryId',
      label: 'product.productClassify',
      colSpan: 8,
      option: {
        placeholder: 'product.selectClassify',
        filterable: true,
        clearable: true,
        collapseTags: true,
        showAllLevels: false,
        props: {
          checkStrictly: true,
          emitPath: false
        }
      },
      optionData: []
    },
    {
      type: 'DateRangePicker',
      field: 'requiredDeliveryTime',
      label: 'projectProduct.shippingDate',
      colSpan: 8,
      option: {
        rangeSeparator: '~'
      }
    }
  ];

  /**
   * 总记录数
   */
  public totalData = 0;

  public selectedRows: Array<ProjectProductList> = [];
  public selectedRow: ProjectProductList | null = null;

  public created(): void {
    if (this.isMultiple) {
      this.tableColumnOption.unshift({
        type: 'selection',
        prop: 'id',
        label: '',
        reserveSelection: true,
        fixed: true
      });
    }
    // 加载项目产品分类数据
    this.loadCategoryData();
  }

  public dialogOpen(): void {
    // 加载项次数据
    this.loadData();
  }

  /**
   * 弹窗关闭后事件
   */
  public dialogClosed(): void {
    this.clearSelection();
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    if (this.isMultiple) {
      ((this.$refs.productTable as OsTable).tableRef as Table).clearSelection();
    } else {
      (this.$refs.productTable as OsTable).clearRadioSelection();
    }
  }

  /**
   * 数据多选事件
   */
  public handleSelectionChange(selectedValues: Array<ProjectProductList>): void {
    this.selectedRows = [...selectedValues];
  }

  /**
   * 数据单选事件
   */
  public handleRadioSelectionChange(selectedValue: ProjectProductList): void {
    this.selectedRow = selectedValue;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get radioConfirmDisabled(): boolean {
    return !this.selectedRow;
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.totalData})`;
  }

  /**
   * 分页事件
   */
  public handlePaging(): void {
    this.loadData();
  }

  /**
   * 提交事件
   */
  public onSubmit(): void {
    if (this.isMultiple) {
      this.$emit('confirm', this.selectedRows);
    } else {
      this.$emit('confirm', this.selectedRow);
    }
    this.syncedVisible = false;
  }

  public queryClick(): void {
    this.clearSelection();
    this.loadData();
  }

  /**
   * 加载项目产品数据
   */
  private loadData(): void {
    (this.$refs.productTable as OsTable).openLoading();
    this.queryForm.projectItemIdList = this.projectItemIdList;
    this.queryForm.supplierId = this.supplierId;
    productionProductSearchService
      .getList(this.queryForm, this.paging)
      .then(res => {
        const list: Array<ProjectProductList> = res.data.map(item => {
          return {
            ...item,
            id: item.itemId
          };
        });
        this.tableOption.data = list;
        this.totalData = res.total || res.data.length;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.productTable as OsTable).closeLoading();
      });
  }

  /**
   * 加载项目相关产品分类数据
   */
  private loadCategoryData(): void {
    // productionProductSearchService
    //   .listAllProductCateogryByProject
    classifyService
      .getTreeData()
      .then(res => {
        const option = this.queryItemsOption.find(x => x.field === 'categoryId');
        if (!option) {
          return;
        }
        option.optionData = classifyService.handleCascaderOption(res as any);
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('queryForm.requiredDeliveryTime')
  private handleDeliveryTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.requiredDeliveryStartTime = undefined;
      this.queryForm.requiredDeliveryEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.requiredDeliveryStartTime = dateFormat(value[0]);
      this.queryForm.requiredDeliveryEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }
}
