import { classifyService, materialListService } from '@/api';
import { FlagParams, MaterialSourceList } from '@/resource/model';
import { Component, Prop, PropSync } from 'vue-property-decorator';

import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTable } from '@/components';
import { messageError, translation } from '@/utils';
import { Table } from 'element-ui/types/element-ui';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { RegionTreeResource } from '@/resource/model/public-data/region';
import { MaterialPropertyEnum } from '@/resource/enum';
@Component({
  name: 'SelectMaterial',
  components: {}
})
export default class SelectMaterial extends mixins(PagingMixin) {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false }) public syncedVisible!: boolean;
  @Prop({ default: false, type: Boolean, required: false }) public isMultiple!: boolean;
  @Prop({
    type: Object,
    required: false,
    default: null
  })
  public flagParams!: FlagParams;
  public pageTotal = 0;
  public tableOption: OsTableOption<MaterialSourceList> = {
    loading: false,
    data: [],
    fit: true,
    height: 500,
    radioSelection: !this.isMultiple,
    closeAdaptiveHeight: true
  };
  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<MaterialSourceList>> = [
    {
      prop: 'name',
      label: 'database.name',
      minWidth: '320px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'database.code',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'specification',
      label: 'materialList.specification',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'attribute',
      label: 'materialList.attribute',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'baseUnitName',
      label: 'materialList.baseUnit',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'brand',
      label: 'materialList.brand',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'database.description',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'code',
      label: 'database.code',
      option: {
        placeholder: 'database.inputCode'
      }
    },
    {
      type: 'Input',
      field: 'name',
      label: 'database.name',
      option: {
        placeholder: 'database.inputName'
      }
    },
    {
      type: 'Select',
      field: 'attribute',
      label: 'materialList.attribute',
      option: {
        placeholder: 'common.select'
      },
      optionData: materialListService.getAttributeList()
    }
  ];
  public treeList: Array<RegionTreeResource> = [
    {
      id: 0,
      level: 0,
      name: '全部',
      valueList: [0],
      child: []
    }
  ];

  public parentData: RegionTreeResource = this.treeList[0];
  public selectedRows: Array<MaterialSourceList> = [];
  public selectedRow: MaterialSourceList | null = null;
  public defaultProps: { children: string; label: string } = {
    children: 'child',
    label: 'name'
  };
  private queryForm: Partial<{
    name: string;
    code: string;
    categoryId: number | null;
    attribute: string;
  }> = {
    name: '',
    code: '',
    categoryId: null,
    attribute: ''
  };
  public getAttribute(attribute: MaterialPropertyEnum): string {
    return attribute ? translation(`materialPropertyEnum.${attribute}`) : '';
  }

  public created(): void {
    if (this.isMultiple) {
      this.columnOption.unshift({
        type: 'selection',
        prop: 'id',
        label: '',
        reserveSelection: true
      });
    }
  }

  public async dialogOpen(): Promise<void> {
    await this.getTreeList();
    this.reloadData();
  }

  public dialogClosed(): void {
    this.resetQuery();
    this.clearSelection();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public handleNodeClick(nodeData: any): void {
    this.parentData = nodeData;
    this.clearSelection();
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.getMaterialList();
  }

  public handlePaging(): void {
    this.getMaterialList();
  }

  public handleSelectionChange(selectedValues: Array<MaterialSourceList>): void {
    this.selectedRows = [...selectedValues];
  }

  public handleRadioSelectionChange(selectedValue: MaterialSourceList): void {
    this.selectedRow = selectedValue;
  }

  public async onSubmit(): Promise<void> {
    if (this.isMultiple) {
      const selectedRows = await this.handleAssignment(this.selectedRows);
      this.$emit('confirm', selectedRows);
    } else {
      const selectedRow = await this.handleAssignment([this.selectedRow!]);
      this.$emit('confirm', selectedRow[0]);
    }
    this.syncedVisible = false;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get radioConfirmDisabled(): boolean {
    return !this.selectedRow;
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    if (this.isMultiple) {
      ((this.$refs.unitMeasureTable as OsTable).tableRef as Table).clearSelection();
    } else {
      (this.$refs.unitMeasureTable as OsTable).clearRadioSelection();
    }
  }
  // 赋值
  public async handleAssignment(list: Array<MaterialSourceList>): Promise<Array<MaterialSourceList>> {
    const ids: Array<number> = list.map((x: MaterialSourceList) => x.id!);
    // const saleList = await materialListService.getListForSaleComponents(ids);
    // const purchaseList = await materialListService.getListForPurchaseComponents(ids);
    const inventoryList = await materialListService.getListForInventoryComponents(ids);
    // const productionList = await materialListService.getListForProductionComponents(ids);
    list.forEach(item => {
      // item.sale = saleList.find(x => {
      //   return x.id === item.id;
      // })!;
      // item.purchase = purchaseList.find(x => {
      //   return x.id === item.id;
      // })!;
      item.inventory = inventoryList.find(x => {
        return x.id === item.id;
      })!;
      // item.production = productionList.find(x => {
      //   return x.id === item.id;
      // })!;
    });
    return list;
  }

  private getMaterialList(): void {
    this.tableOption.loading = true;
    if (!this.parentData!.id!) {
      this.queryForm.categoryId = null;
    } else {
      this.queryForm.categoryId = this.parentData!.id ? this.parentData!.id : null;
    }
    materialListService
      .getListForComponents({ ...this.queryForm, ...this.flagParams } as any, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
        this.pageTotal = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private resetQuery(): void {
    this.queryForm = {
      name: '',
      code: '',
      categoryId: null
    };
  }

  private getTreeList(): void {
    classifyService
      .getTreeData()
      .then(res => {
        res.forEach(item => {
          item.name = `${item.categoryName}`;
          item.level = 1;
          if (item.children) {
            item.children.forEach((cur: any) => {
              cur.name = `${cur.categoryName}`;
              cur.level = 2;
            });
            item.child = item.children;
          }
        });
        this.treeList[0].child = res as any;
        this.parentData = this.treeList[0];
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
