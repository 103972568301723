import { unitsMeasureGroupsService, unitsMeasureService } from '@/api';
import { UnitOfMeasurementListQuery, UnitOfMeasurementList } from '@/resource/model';
import { Component, Prop, PropSync } from 'vue-property-decorator';

import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTable } from '@/components';
import { messageError, translation } from '@/utils';
import { Table } from 'element-ui/types/element-ui';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { RegionTreeResource } from '@/resource/model/public-data/region';
import { PublicApproveStatusEnum, ResourceStatusEnum } from '@/resource/enum';
@Component({
  name: 'SelectUnitMeasure',
  components: {}
})
export default class SelectUnitMeasure extends mixins(PagingMixin) {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false }) public syncedVisible!: boolean;
  @Prop({ default: false, type: Boolean, required: false }) public isMultiple!: boolean;
  public pageTotal = 0;
  public tableOption: OsTableOption<UnitOfMeasurementList> = {
    loading: false,
    data: [],
    fit: true,
    height: 500,
    radioSelection: !this.isMultiple,
    closeAdaptiveHeight: true
  };
  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<UnitOfMeasurementList>> = [
    {
      prop: 'code',
      label: 'unitsMeasure.code',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'name',
      label: 'unitsMeasure.name',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'datumFlag',
      label: 'unitsMeasure.datumFlag',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return translation(`${this.getDatumFlagI18Key((row as UnitOfMeasurementList).datumFlag)}`);
      }
    },
    {
      prop: 'systemFlag',
      label: 'unitsMeasure.systemFlag',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'code',
      label: 'database.code',
      colSpan: 9,
      option: {
        placeholder: 'database.inputCode'
      }
    },
    {
      type: 'Input',
      field: 'name',
      label: 'database.name',
      colSpan: 9,
      option: {
        placeholder: 'database.inputName'
      }
    }
  ];
  public treeList: Array<RegionTreeResource> = [
    {
      id: 0,
      level: 0,
      name: '全部',
      valueList: [0],
      child: []
    }
  ];

  public parentData: RegionTreeResource = this.treeList[0];
  public selectedRows: Array<UnitOfMeasurementList> = [];
  public selectedRow: UnitOfMeasurementList | null = null;
  public defaultProps: { children: string; label: string } = {
    children: 'child',
    label: 'name'
  };
  private queryForm: Partial<{
    name: string;
    code: string;
    groupId: number | null;
  }> = {
    name: '',
    code: '',
    groupId: null
  };

  public getStatusI18Key(status: ResourceStatusEnum): string {
    let res;
    switch (Number(status)) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return res;
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return Number(status) === ResourceStatusEnum.using ? 'start-dot' : 'disabled-dot';
  }

  public getSystemFlagI18Key(systemFlag: number): string {
    return systemFlag === 1 ? 'common.yes' : 'common.no';
  }
  public getDatumFlagI18Key(datumFlag: number): string {
    return Number(datumFlag) === 1 ? 'common.yes' : 'common.no';
  }
  public getApproveStatusClass(status: PublicApproveStatusEnum): string {
    return Number(status) === PublicApproveStatusEnum.passed ? 'success-dot' : 'disabled-dot';
  }

  public getApproveStatusI18Key(status: PublicApproveStatusEnum): string {
    return 'publicApproveStatus.' + PublicApproveStatusEnum[status];
  }

  public async created(): Promise<void> {
    if (this.isMultiple) {
      this.columnOption.unshift({
        type: 'selection',
        prop: 'id',
        label: '',
        reserveSelection: true
      });
    }
  }

  public async dialogOpen(): Promise<void> {
    await this.getTreeList();
    this.reloadData();
  }

  public dialogClosed(): void {
    this.resetQuery();
    this.clearSelection();
  }

  public queryClick(): void {
    if (!this.isMultiple) {
      (this.$refs.unitMeasureTable as OsTable).clearRadioSelection();
    }
    this.reloadData();
  }

  public handleNodeClick(nodeData: any): void {
    this.parentData = nodeData;
    this.clearSelection();
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.getUnitList();
  }

  public handlePaging(): void {
    this.getUnitList();
  }

  public handleSelectionChange(selectedValues: Array<UnitOfMeasurementList>): void {
    this.selectedRows = [...selectedValues];
  }

  public handleRadioSelectionChange(selectedValue: UnitOfMeasurementList): void {
    this.selectedRow = selectedValue;
  }

  public onSubmit(): void {
    if (this.isMultiple) {
      this.$emit('confirm', this.selectedRows);
    } else {
      this.$emit('confirm', this.selectedRow);
    }
    this.syncedVisible = false;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get radioConfirmDisabled(): boolean {
    return !this.selectedRow;
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    if (this.isMultiple) {
      ((this.$refs.unitMeasureTable as OsTable).tableRef as Table).clearSelection();
    } else {
      (this.$refs.unitMeasureTable as OsTable).clearRadioSelection();
    }
  }

  private getUnitList(): void {
    this.tableOption.loading = true;
    if (!this.parentData!.id!) {
      this.queryForm.groupId = null;
    } else {
      this.queryForm.groupId = this.parentData!.id ? this.parentData!.id : null;
    }
    unitsMeasureService
      .getListForComponents(this.queryForm as UnitOfMeasurementListQuery, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private resetQuery(): void {
    this.queryForm = {
      name: '',
      code: ''
    };
  }

  private getTreeList(): void {
    unitsMeasureGroupsService
      .getUnitsOfMeasureList()
      .then(res => {
        res.forEach(item => {
          item.name = `${item.code}(${item.name})`;
        });
        this.treeList[0].child = res as any;
        this.parentData = this.treeList[0];
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
