import { ApproveUser, TreeNode, WorkflowDesignResource } from '@/resource/model';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { messageError, messageErrors, translation } from '@/utils';
import { UserModule } from '@/store/modules/user';
import OrgItems from './components/org-items/org-items.vue';
import { personnelService, workflowDesignService } from '@/api';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';

@Component({
  name: 'InitiateApproval',
  components: { OrgItems }
})
export default class InitiateApproval extends Vue {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false })
  public syncedVisible!: boolean;

  @Prop({ required: true, default: null, type: Object })
  public workflowDesign!: WorkflowDesignResource;

  @Prop({ required: true, default: 0, type: Number })
  public businessId: number = 0;

  @Prop({
    required: false,
    default: undefined,
    type: Function
  })
  public save!: Function;

  public saveLoading: boolean = false;
  public workflowDesignList: Array<TreeNode> = [];

  public leadershipOptions: Array<{ id: number; name: string; isEdit: boolean }> = [];

  public initiateApprovalForm: Partial<{
    leadership: number;
  }> = {
    leadership: undefined
  };
  public formRules = {
    leadership: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select') + translation('taskCenter.leadership')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    approvalProcess: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          callback();
        },
        trigger: 'blur'
      }
    ]
  };
  private get user(): { id: number; name: string } {
    return UserModule;
  }
  private get _businessId(): Number {
    return this.businessId;
  }
  public dialogOpen(): void {
    this.workflowDesignList = [];
    this.initiateApprovalForm.leadership = undefined;
    this.handleWorkflowDesign(JSON.parse(this.workflowDesign.flowInfoJson) as TreeNode);
    // const flowInfoJson = {
    //   id: 'root',
    //   parentId: '',
    //   type: 'ROOT',
    //   name: '发起人',
    //   desc: '任何人',
    //   props: { assignedUser: [], assignedType: 'ASSIGN_USER', mode: 'NEXT', sign: false },
    //   children: {
    //     id: 'node_522794867072',
    //     parentId: 'root',
    //     props: { assignedType: 'DIRECT_MANAGER', assignedUser: [], mode: 'NEXT' },
    //     type: 'APPROVAL',
    //     name: '审批人',
    //     children: {
    //       id: 'node_524710984547',
    //       parentId: 'node_522794867072',
    //       props: {
    //         assignedType: 'ASSIGN_USER',
    //         assignedUser: [
    //           { id: 3, name: '陈佳凯' },
    //           { id: 4, name: '张红菊' },
    //           { id: 5, name: '杨阳' },
    //           { id: 6, name: '吴可婷' },
    //           { id: 7, name: '叶柳青' },
    //           { id: 8, name: '黄莉莉' }
    //         ],
    //         mode: 'AND'
    //       },
    //       type: 'APPROVAL',
    //       name: '审批人',
    //       children: {
    //         id: 'node_761703229558',
    //         parentId: 'node_524710984547',
    //         props: {
    //           assignedType: 'ASSIGN_USER',
    //           assignedUser: [{ id: 9, name: '张逸飞', isEdit: false }],
    //           mode: 'NEXT'
    //         },
    //         type: 'APPROVAL',
    //         name: '审批人',
    //         children: {
    //           id: 'node_769418078463',
    //           parentId: 'node_761703229558',
    //           props: { assignedType: 'DIRECT_MANAGER', assignedUser: [] },
    //           type: 'CC',
    //           name: '抄送人',
    //           children: {
    //             id: 'node_769486911404',
    //             parentId: 'node_769418078463',
    //             props: {
    //               assignedType: 'ASSIGN_USER',
    //               assignedUser: [
    //                 { id: 7, name: '叶柳青', isEdit: false },
    //                 { id: 8, name: '黄莉莉', isEdit: false },
    //                 { id: 9, name: '张逸飞', isEdit: false }
    //               ]
    //             },
    //             type: 'CC',
    //             name: '抄送人',
    //             children: {}
    //           }
    //         }
    //       }
    //     }
    //   }
    // };
    // this.handleWorkflowDesign(flowInfoJson as any);
    this.getLeader();
  }

  public dialogClosed(): void {}

  /**
   * 将数据TreeNode打平为数组
   * @param node
   */
  public handleWorkflowDesign(node: TreeNode): void {
    if (node.id === 'root') {
      node.props!.assignedUser = [
        { name: this.user.name, id: this.user.id, isEdit: false, approveStatus: 0, context: '' }
      ];
    }
    const nodeData = {
      id: node.id,
      name: node.name,
      props: node.props,
      status: 0,
      type: node.type
    };
    this.workflowDesignList.push(nodeData);
    console.log('判断是否有子节点', !!node.children);
    if (node.children?.id) {
      this.handleWorkflowDesign(node.children);
    } else {
      this.workflowDesignList.push({
        id: 'end',
        name: '结束',
        type: 'end',
        status: 0
      } as TreeNode);
    }
  }

  /**
   * 是否展示人员添加按钮
   * @param node
   * @returns
   */
  public isShowAdd(node: TreeNode): boolean {
    return (
      ['root', 'end'].includes(node.id!) ||
      ['DIRECT_MANAGER'].includes(node.props!.assignedType) ||
      ['NEXT'].includes(node.props!.mode!) ||
      node.props?.assignedUser.length === 10
    );
  }
  public tip(node: TreeNode): string {
    let tipStr = '';
    switch (node.props?.mode) {
      case 'AND':
        tipStr = '（全部通过即通过）';
        break;
      case 'OR':
        tipStr = '（一人通过即通过）';
        break;
    }

    if (node.props?.assignedType === 'DIRECT_MANAGER') {
      tipStr = '（直属领导）';
    }
    return tipStr;
  }

  /**
   * 是否展示直属领导选框
   */
  public get isShowLeadership(): boolean {
    let isShow = false;
    this.workflowDesignList.forEach(node => {
      if (node.props?.assignedType === 'DIRECT_MANAGER') {
        isShow = true;
      }
    });
    return isShow;
  }

  /**
   * 修改人员节点信息
   * @param userList
   * @param index
   */
  public editAssignedUser(userList: Array<ApproveUser>, index: number): void {
    this.workflowDesignList[index].props!.assignedUser = userList;
  }

  /**
   * 领导赋值
   * @param userId
   */
  public selectLeadership(userId: number): void {
    const user = {
      id: userId,
      name: this.leadershipOptions.filter(u => u.id === userId)[0].name,
      isEdit: false,
      context: ''
    };
    this.leadershipChange(user);
  }

  /**
   * 领导赋值
   * @param userId
   */
  public leadershipChange(user: ApproveUser): void {
    this.workflowDesignList.forEach(node => {
      if (node.props?.assignedType === 'DIRECT_MANAGER') {
        if (node.type === 'CC') {
          node.props.assignedUser = [];
          user.approveStatus = 5;
          node.props.assignedUser.push(user);
        } else {
          node.props.assignedUser = [];
          node.props.assignedUser.push(user);
        }
      }
    });
  }

  public async getLeader(): Promise<void> {
    try {
      this.leadershipOptions = [];
      const leaderList = await personnelService.getLeader();
      if (leaderList.length === 0 && this.isShowLeadership) {
        this.leadershipChange({ id: this.user.id, name: this.user.name, isEdit: false, context: '' });
      } else {
        leaderList.forEach(user => {
          this.leadershipOptions.push({ id: user.id, name: user.realName, isEdit: false });
        });
      }
    } catch (error) {
      messageError(error);
    }
  }

  public assemblyData(workflowDesignList: Array<TreeNode>): Object {
    const paramsMap = new Map();
    const length = workflowDesignList.length - 1;
    workflowDesignList.forEach((node, index) => {
      if (index <= length) {
        let value: Array<number | string> | number = [];
        if ((node.props?.mode && ['OR', 'AND'].includes(node.props?.mode)) || node.type === 'CC') {
          value = node.props?.assignedUser.map(u => String(u.id)) ?? [];
        } else {
          value = node.props?.assignedUser[0].id ?? [];
        }
        paramsMap.set(`${node.id}`, value);
      }
    });

    return Object.fromEntries(paramsMap);
  }

  public onSubmit(): void {
    (this.$refs.initiateApprovalForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      try {
        this.saveLoading = true;
        if (this.save) {
          await this.save();
        }
        const params = {
          bizKey: this.workflowDesign.tag,
          businessId: this.businessId,
          json: JSON.stringify(this.workflowDesignList),
          modelId: this.workflowDesign.id,
          paramsMap: this.assemblyData(this.workflowDesignList)
        };
        await workflowDesignService.instance(params);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('success');
        this.syncedVisible = false;
      } catch (error) {
        if (error instanceof Array) {
          messageErrors(error);
        } else {
          messageError(error);
        }
      } finally {
        this.saveLoading = false;
      }
    });
  }
}
