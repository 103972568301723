import { minioService } from '@/api';
import { MinIOFile } from '@/api/minio';
import { OsFileUploadOptions } from '@/components/os-file-upload/os-file-upload';
import { ImportTemplateEnum } from '@/resource/enum';
import { UserModule } from '@/store/modules/user';
import { dateFormat, downloadFileByPath, messageError, translation } from '@/utils';
import { HttpRequestOptions } from 'element-ui/types/upload';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { OsFileUpload } from '@/components';
import { axiosRequest } from '@/utils/axios-request';
@Component({
  name: 'import-file'
})
export default class ImportFile extends Vue {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false }) public syncedVisible!: boolean;
  /**
   * 传入title国际化的标识
   */
  @Prop({ type: String, default: 'button.import' }) public title!: string;

  @Prop({ type: String, required: false, default: '' }) public importTemplate!: ImportTemplateEnum;

  @Prop({ type: String, required: false, default: '.xls,.xlsx' }) public acceptFileType!: string;

  @Prop({ type: Number, required: false, default: 5 }) public acceptFileSize!: number;

  @Prop({ type: Boolean, required: false, default: true }) public showDownloadTemplate!: boolean;

  public downloadLoading = false;

  public fileImportOptions: OsFileUploadOptions = {
    autoUpload: false,
    limit: 1,
    multiple: false,
    drag: true,
    action: '',
    accept: this.acceptFileType,
    maxSize: this.acceptFileSize,
    uploadLoading: false,
    httpRequest: this.uploadFile,
    loadingText: translation('dialog.importLoadingText')
  };

  private readonly importPath = 'platform/import/';

  public dialogOpen(): void {
    minioService.init();
  }

  public dialogClosed(): void {
    this.setLoading(false);
    (this.$refs.upload as OsFileUpload).clearFiles();
  }

  public confirmImport(): void {
    (this.$refs.upload as OsFileUpload).submit();
  }

  public async downloadTemplate(): Promise<void> {
    if (!this.importTemplate) {
      this.$emit('download-template');
      return;
    }
    try {
      this.downloadLoading = true;
      await minioService.init();
      const path = await minioService.getDownLoadPath(`${this.importTemplate}`, 'template');
      downloadFileByPath(this.importTemplate, path);
      this.downloadLoading = false;
    } catch (error) {
      messageError(error);
    }
  }

  public async uploadFile(requestOptions: HttpRequestOptions): Promise<void> {
    try {
      this.setLoading(true);

      const minioFile: MinIOFile = {
        name: `${dateFormat(new Date())}_${requestOptions.file.name}`,
        stream: Buffer.from(await requestOptions.file.arrayBuffer())
      };
      await minioService.init();
      await minioService.uploadByStream(minioFile, `${this.importPath}${UserModule.account}`);

      const path = await minioService.getDownLoadPath(`${this.importPath}${UserModule.account}/${minioFile.name}`);
      this.$emit('import-success', path);
    } catch (error) {
      this.setLoading(false);
      messageError(error);
    } finally {
      (this.$refs.upload as OsFileUpload).clearFiles();
    }
  }

  /**
   *  设置导入文件时的loading状态
   * @param loading 是否开启loading
   */
  public setLoading(loading: boolean): void {
    this.fileImportOptions.uploadLoading = loading;
  }

  /**
   * 设置下载模板时的loading状态
   * @param loading 是否开启loading
   */
  public setDownloadLoading(loading: boolean): void {
    this.downloadLoading = loading;
  }

  public closeDialog(): void {
    this.syncedVisible = false;
    axiosRequest.clearPendingRequest();
  }
}
