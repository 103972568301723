import { render, staticRenderFns } from "./select-material-classify.vue?vue&type=template&id=49ef2e79&scoped=true&"
import script from "./select-material-classify.ts?vue&type=script&lang=ts&"
export * from "./select-material-classify.ts?vue&type=script&lang=ts&"
import style0 from "./select-material-classify.scoped.scss?vue&type=style&index=0&id=49ef2e79&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49ef2e79",
  null
  
)

export default component.exports