import { warehouseService } from '@/api';
import { WarehouseList } from '@/resource/model';
import { Component, Prop, PropSync } from 'vue-property-decorator';

import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTable } from '@/components';
import { messageError } from '@/utils';
import { Table } from 'element-ui/types/element-ui';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
@Component({
  name: 'SelectWarehouse',
  components: {}
})
export default class SelectWarehouse extends mixins(PagingMixin) {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false }) public syncedVisible!: boolean;
  /**
   * 控制单选或者多选
   */
  @Prop({ default: true, type: Boolean, required: false }) public isMultiple!: boolean;
  /**
   * 是否启用仓位管理
   */
  @Prop({ default: 1, type: Number, required: false }) public positionFlag!: number;
  public pageTotal = 0;
  public tableOption: OsTableOption<WarehouseList> = {
    loading: false,
    data: [],
    fit: true,
    radioSelection: !this.isMultiple,
    closeAdaptiveHeight: true
  };
  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<WarehouseList>> = [
    {
      prop: 'code',
      label: 'database.code',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'name',
      label: 'database.name',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'database.description',
      showOverflowTooltip: true,
      minWidth: '150px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'code',
      label: 'database.code',
      colSpan: 9,
      option: {
        placeholder: 'database.inputCode'
      }
    },
    {
      type: 'Input',
      field: 'name',
      label: 'database.name',
      colSpan: 9,
      option: {
        placeholder: 'database.inputName'
      }
    }
  ];
  public selectedRows: Array<WarehouseList> = [];
  public selectedRow: WarehouseList | null = null;
  private queryForm: Partial<{
    name: string;
    code: string;
    positionFlag: number;
  }> = {
    name: '',
    code: '',
    positionFlag: this.positionFlag
  };

  public created(): void {
    if (this.isMultiple) {
      this.columnOption.unshift({
        type: 'selection',
        prop: 'id',
        label: '',
        reserveSelection: true
      });
    }
  }

  public dialogOpen(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    this.resetQuery();
    this.clearSelection();
  }

  public queryClick(): void {
    if (!this.isMultiple) {
      (this.$refs.warehouseTable as OsTable).clearRadioSelection();
    }
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.getWarehouseList();
  }

  public handlePaging(): void {
    this.getWarehouseList();
  }

  public handleSelectionChange(selectedValues: Array<WarehouseList>): void {
    this.selectedRows = [...selectedValues];
  }

  public handleRadioSelectionChange(selectedValue: WarehouseList): void {
    this.selectedRow = selectedValue;
  }

  public onSubmit(): void {
    if (this.isMultiple) {
      this.$emit('confirm', this.selectedRows);
    } else {
      this.$emit('confirm', this.selectedRow);
    }
    this.syncedVisible = false;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get radioConfirmDisabled(): boolean {
    return !this.selectedRow;
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    if (this.isMultiple) {
      ((this.$refs.warehouseTable as OsTable).tableRef as Table).clearSelection();
    } else {
      (this.$refs.warehouseTable as OsTable).clearRadioSelection();
    }
  }

  private getWarehouseList(): void {
    this.tableOption.loading = true;
    this.queryForm.positionFlag = this.positionFlag;
    warehouseService
      .getSelectList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
        if (this.pageTotal === 0) {
          this.pageTotal = res.total;
        }
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private resetQuery(): void {
    this.queryForm = {
      name: '',
      code: '',
      positionFlag: this.positionFlag
    };
  }
}
