import SelectProductionOrderProduct from '@/views/dialogs/select-production-order-product/select-production-order-product.vue';
import ImportFile from './import-file/import-file.vue';
import SelectCustomerProduct from './select-customer-product/select-customer-product.vue';
import SelectPlatformProduct from './select-platform-product/select-platform-product.vue';
import SelectPosition from './select-position/select-position.vue';
import SelectProjectProduct from './select-project-product/select-project-product.vue';
import SelectServiceItem from './select-service-item/select-service-item.vue';
import SelectWarehouse from './select-warehouse/select-warehouse.vue';
import SelectStoreLocation from './select-store-location/select-store-location.vue';
import SelectUnitMeasure from './select-unit-measure/select-unit-measure.vue';
import SelectCurrency from './select-currency/select-currency.vue';
import SelectMaterialClassify from './select-material-classify/select-material-classify.vue';
import SelectInventoryType from './select-inventory-type/select-inventory-type.vue';
import UploadFile from './upload-file/upload-file.vue';
import SelectMaterial from './select-material/select-material.vue';
import InitiateApproval from './initiate-approval/initiate-approval.vue';
import BatchSetupData from './batch-setup-data/batch-setup-data.vue';

export {
  SelectPosition,
  ImportFile,
  SelectPlatformProduct,
  SelectCustomerProduct,
  SelectProjectProduct,
  SelectProductionOrderProduct,
  SelectServiceItem,
  SelectWarehouse,
  SelectStoreLocation,
  SelectUnitMeasure,
  SelectCurrency,
  SelectMaterialClassify,
  SelectInventoryType,
  SelectMaterial,
  InitiateApproval,
  BatchSetupData,
  UploadFile
};
