import { classifyService, productService } from '@/api';
import { ProductList } from '@/resource/model';
import { Component, Prop, PropSync } from 'vue-property-decorator';

import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTable, OsTableQuery, OsPagination } from '@/components';
import { ResourceStatusEnum } from '@/resource/enum';
import { messageError } from '@/utils';
import { Table } from 'element-ui/types/element-ui';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
@Component({
  name: 'select-platform-product',
  components: { OsTable, OsTableQuery, OsPagination }
})
export default class SelectPlatformProduct extends mixins(PagingMixin) {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false }) public syncedVisible!: boolean;

  @Prop({ default: true, type: Boolean, required: false }) public isMultiple!: boolean;

  public pageTotal = 0;
  public tableOption: OsTableOption<ProductList> = {
    loading: false,
    data: [],
    fit: true,
    radioSelection: !this.isMultiple
  };
  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<ProductList>> = [
    {
      prop: 'productCode',
      label: 'product.code',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    { prop: 'productName', label: 'product.name', minWidth: '150px', showOverflowTooltip: true },
    {
      prop: 'categoryName',
      label: 'product.productClassify',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (row: Object): string => {
        const data: ProductList = row as ProductList;

        const list = [data.categoryName];
        if (data.secondCategoryName) {
          list.push(data.secondCategoryName);
        }

        return list.join(' / ');
      }
    },
    { prop: 'remark', label: 'common.remark', minWidth: '150px', showOverflowTooltip: true }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      },
      colSpan: 9
    },
    {
      type: 'Cascader',
      field: 'categoryId',
      label: 'product.productClassify',
      option: {
        placeholder: 'product.selectClassify',
        filterable: true,
        clearable: true,
        collapseTags: true,
        showAllLevels: false,
        props: {
          checkStrictly: true,
          emitPath: false
        }
      },
      colSpan: 9,
      optionData: []
    }
  ];
  public selectedRows: Array<ProductList> = [];
  public selectedRow: ProductList | null = null;
  private queryForm: Partial<{
    /**
     * 产品分类
     */
    categoryId: number | null;
    keywords?: string;
    status: ResourceStatusEnum;
  }> = {
    keywords: '',
    categoryId: null,
    status: ResourceStatusEnum.using
  };
  private selectedProductIds: Array<number> = [];
  private selectedProductId: number | null = null;

  public created(): void {
    if (this.isMultiple) {
      this.columnOption.unshift({
        type: 'selection',
        prop: 'id',
        label: '',
        reserveSelection: true
      });
    }
  }

  public dialogOpen(): void {
    this.reloadData();
    this.getProductClassify();
    // this.selectedProductIds = this.productIds;
    // this.selectedProductId = this.productId;
  }

  public dialogClosed(): void {
    this.resetQuery();
    this.clearSelection();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.getProduct();
  }

  public handlePaging(): void {
    this.getProduct();
  }

  public handleSelectionChange(selectedValues: Array<ProductList>): void {
    this.selectedRows = [...selectedValues];
  }

  public handleRadioSelectionChange(selectedValue: ProductList): void {
    this.selectedRow = selectedValue;
  }

  public onSubmit(): void {
    if (this.isMultiple) {
      this.$emit('confirm', this.selectedRows);
    } else {
      this.$emit('confirm', this.selectedRow);
    }
    this.syncedVisible = false;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get radioConfirmDisabled(): boolean {
    return !this.selectedRow;
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    if (this.isMultiple) {
      ((this.$refs.productTable as OsTable).tableRef as Table).clearSelection();
    } else {
      (this.$refs.productTable as OsTable).clearRadioSelection();
    }
  }

  private getProduct(): void {
    this.tableOption.loading = true;
    productService
      .listEnable(this.queryForm as ProductList, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
        if (this.pageTotal === 0) {
          this.pageTotal = res.total;
        }
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getProductClassify(): void {
    classifyService
      .getTreeData()
      .then(res => {
        const queryOption = this.queryItemsOption.find(x => x.field === 'categoryId');
        if (!queryOption) {
          return;
        }
        queryOption.optionData = classifyService.handleCascaderOption(res);
      })
      .catch(error => {
        messageError(error);
      });
  }

  private resetQuery(): void {
    this.queryForm = {
      keywords: '',
      categoryId: null,
      status: ResourceStatusEnum.using
    };
  }
}
