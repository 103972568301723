import { ServiceItemList } from '@/resource/model';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsTable } from '@/components';
import { messageError, translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { BusinessTypeEnum, PublicApproveStatusEnum, ResourceStatusEnum } from '@/resource/enum';
import { ServiceItemService } from '@/api';
import { Table } from 'element-ui';
import { PagingMixin } from '@/mixins/paging';

@Component({
  name: 'SelectServiceItem',
  components: {}
})
export default class SelectServiceItem extends mixins(PagingMixin) {
  @PropSync('visible', { default: false }) public syncedVisible!: boolean;
  @Prop({ default: true, type: Boolean, required: false }) public isMultiple!: boolean;
  public pageTotal = 0;

  public tableOption: OsTableOption<ServiceItemList> = {
    loading: false,
    data: [],
    fit: true,
    radioSelection: !this.isMultiple
  };
  /**
   * 表格列配置
   */
  public columnOptions: Array<OsTableColumn<ServiceItemList>> = [
    {
      prop: 'code',
      label: 'serviceItem.code',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'name',
      label: 'serviceItem.name',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'type',
      label: 'serviceItem.type',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return translation(`businessType.${BusinessTypeEnum[(row as ServiceItemList).type!]}`);
      }
    },

    {
      prop: 'approveStatus',
      label: 'serviceItem.approveStatus',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'enableStatus',
      label: 'serviceItem.enableStatus',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  public selectedRow: ServiceItemList | null = null;
  public selectedRows: Array<ServiceItemList> = [];

  public getStatusI18Key(status: ResourceStatusEnum): string {
    let res;
    switch (status) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return res;
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return status === ResourceStatusEnum.using ? 'start-dot' : 'disabled-dot';
  }
  public getApproveStatusClass(status: PublicApproveStatusEnum): string {
    return status === PublicApproveStatusEnum.passed ? 'success-dot' : 'disabled-dot';
  }

  public getApproveStatusI18Key(status: PublicApproveStatusEnum): string {
    return 'publicApproveStatus.' + PublicApproveStatusEnum[status];
  }
  public dialogOpen(): void {
    // 加载项次数据
    this.getServiceItem();
  }
  public created(): void {
    if (this.isMultiple) {
      this.columnOptions.unshift({
        type: 'selection',
        prop: 'id',
        label: '',
        reserveSelection: true
      });
    }
    // 获取数据
    this.getServiceItem();
  }

  public dialogClosed(): void {
    this.clearSelection();
  }

  public handleRadioSelectionChange(selectedValue: ServiceItemList): void {
    this.selectedRow = selectedValue;
  }

  public handleSelectionChange(selectedValues: Array<ServiceItemList>): void {
    this.selectedRows = [...selectedValues];
  }

  public onSubmit(): void {
    if (this.isMultiple) {
      this.$emit('confirm', this.selectedRows);
    } else {
      this.$emit('confirm', this.selectedRow);
    }
    this.syncedVisible = false;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get radioConfirmDisabled(): boolean {
    return !this.selectedRow;
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    if (this.isMultiple) {
      ((this.$refs.serviceItemTable as OsTable).tableRef as Table).clearSelection();
    } else {
      (this.$refs.serviceItemTable as OsTable).clearRadioSelection();
    }
  }
  public handlePaging(): void {
    this.getServiceItem();
  }

  private getServiceItem(): void {
    this.tableOption.loading = true;
    ServiceItemService.listForComponents(this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
        if (this.pageTotal === 0) {
          this.pageTotal = res.total;
        }
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
