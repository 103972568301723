import { render, staticRenderFns } from "./select-position.vue?vue&type=template&id=105d1aec&scoped=true&"
import script from "./select-position.ts?vue&type=script&lang=ts&"
export * from "./select-position.ts?vue&type=script&lang=ts&"
import style0 from "./select-position.scoped.scss?vue&type=style&index=0&id=105d1aec&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "105d1aec",
  null
  
)

export default component.exports