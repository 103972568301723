import { NodeProps } from '@/resource/model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SelectPersonnel from '@/views/dialogs/select-personnel/select-personnel.vue';

@Component({
  name: 'OrgItems',
  components: { SelectPersonnel }
})
export default class OrgItems extends Vue {
  @Prop({ required: true, default: -1, type: Number })
  public index!: number;

  @Prop({ required: true, default: false, type: Boolean })
  public isShowAdd!: boolean;

  @Prop({ required: true, default: {}, type: Object })
  public props!: NodeProps;

  public showSelectPersonnel: boolean = false;
  public get userList(): Array<{ name: string; id: number; isEdit?: boolean }> {
    return this.props.assignedUser;
  }
  public get _isShowAdd(): boolean {
    return !this.isShowAdd;
  }

  public addUser(): void {
    this.showSelectPersonnel = true;
  }

  public handleUserConfirm(userArr: Array<{ id: number; realName: string; userCode: string }>): void {
    const userList = this.props.assignedUser.concat(
      userArr.map(u => {
        return { id: u.id, name: u.realName, isEdit: true, approveStatus: 0, context: '' };
      })
    );
    const uniqueIds: Array<number> = [];
    const uniqueArray: any[] = [];
    userList.forEach(item => {
      if (!uniqueIds.includes(item.id)) {
        uniqueArray.push(item);
        uniqueIds.push(item.id);
      }
    });
    this.$emit('editAssignedUser', uniqueArray.splice(0, 10), this.index);
  }
  public removeOrgItem(index: number): void {
    this.props.assignedUser.splice(index, 1);
  }
}
