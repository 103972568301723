import { CurrencyList, CurrencyListQuery } from '@/resource/model';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsTable } from '@/components';
import { mixins } from 'vue-class-component';
import { Table } from 'element-ui';
import { currencyService } from '@/api';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { PagingMixin } from '@/mixins/paging';
import { messageError } from '@/utils';

@Component({
  name: 'SelectCurrency',
  components: {}
})
export default class SelectCurrency extends mixins(PagingMixin) {
  /**
   * 是否开启多选，默认开启
   */
  @Prop({
    required: false,
    type: Boolean,
    default: false
  })
  public isMultiple!: boolean;
  /**
   * 弹窗显示控制
   */
  @PropSync('visible', {
    default: false
  })
  public syncedVisible!: boolean;

  /**
   * 检索条件
   */
  public queryForm: Partial<CurrencyListQuery> = {
    name: '',
    code: ''
  };

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<CurrencyListQuery>> = [
    {
      type: 'Input',
      field: 'code',
      label: 'unitsMeasureGroups.code',
      option: {
        placeholder: 'unitsMeasureGroups.code'
      }
    },
    {
      type: 'Input',
      field: 'name',
      label: 'unitsMeasureGroups.name',
      option: {
        placeholder: 'unitsMeasureGroups.name'
      }
    },
    {
      type: 'Input',
      field: 'currencyCode',
      label: 'currency.currencyCode',
      option: {
        placeholder: 'currency.currencyCode'
      }
    }
  ];

  public tableOption: OsTableOption<CurrencyList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    height: '400px',
    radioSelection: !this.isMultiple
  };
  /**
   * 表格列配置
   */
  public columnOptions: Array<OsTableColumn<CurrencyList>> = [
    {
      prop: 'code',
      label: 'currency.code',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'name',
      label: 'currency.name',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'currencyCode',
      label: 'currency.currencyCode',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];
  /**
   * 总记录数
   */
  public totalData = 0;
  public selectedRows: Array<CurrencyList> = [];
  public selectedRow: CurrencyList | null = null;
  public created(): void {
    if (this.isMultiple) {
      this.columnOptions.unshift({
        type: 'selection',
        prop: 'id',
        label: '',
        reserveSelection: true,
        fixed: true
      });
    }
  }

  public dialogOpen(): void {
    // 加载项次数据
    this.loadData();
  }

  /**
   * 弹窗关闭后事件
   */
  public dialogClosed(): void {
    this.clearSelection();
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    if (this.isMultiple) {
      ((this.$refs.currencyTable as OsTable).tableRef as Table).clearSelection();
    } else {
      (this.$refs.currencyTable as OsTable).clearRadioSelection();
    }
  }

  /**
   * 数据多选事件
   */
  public handleSelectionChange(selectedValues: Array<CurrencyList>): void {
    this.selectedRows = [...selectedValues];
  }

  /**
   * 数据单选事件
   */
  public handleRadioSelectionChange(selectedValue: CurrencyList): void {
    this.selectedRow = selectedValue;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get radioConfirmDisabled(): boolean {
    return !this.selectedRow;
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.totalData})`;
  }

  /**
   * 分页事件
   */
  public handlePaging(): void {
    this.loadData();
  }

  /**
   * 提交事件
   */
  public onSubmit(): void {
    if (this.isMultiple) {
      this.$emit('confirm', this.selectedRows);
    } else {
      this.$emit('confirm', this.selectedRow);
    }
    this.syncedVisible = false;
  }

  public queryClick(): void {
    this.clearSelection();
    this.loadData();
  }

  /**
   * 加载项目产品数据
   */
  private loadData(): void {
    (this.$refs.currencyTable as OsTable).openLoading();
    currencyService
      .getCurrencyList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total || res.data.length;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.currencyTable as OsTable).closeLoading();
      });
  }
}
